import { useQueryClient } from '@tanstack/vue-query'
import { generateIdPathMap, type INodeGroup } from '@manager'

type QueryKey = [string, string, string, string, number]

const fetchLeaseCardSections = (divisionId: string, fieldGroupId: number) => {
  return fetchAPI<INodeGroup>(
    `/companies/${divisionId}/card/configuration/${fieldGroupId}`,
  )
}

export const useLeaseCardSections = async (
  divisionId: string,
  fieldGroupId: number,
) => {
  const { idPathByPage } = storeToRefs(useManagerStore())
  const queryClient = useQueryClient()

  return queryClient
    .ensureQueryData({
      queryKey: [
        'companies',
        divisionId,
        'card',
        'configuration',
        fieldGroupId,
      ] as QueryKey,
      queryFn: () => fetchLeaseCardSections(divisionId, fieldGroupId),
    })
    .then((data) => {
      if (!data) throw new Error('No data found for field group')
      idPathByPage.value.set(fieldGroupId, generateIdPathMap(data))
      return data
    })
    .catch((e) => {
      throw createError({
        statusMessage: 'Failed to load field group',
        statusCode: e.response?.status,
        cause: e,
        fatal: true,
      })
    })
}

export const usePrefetchLeaseCardSections = (divisionId: string) => {
  const queryClient = useQueryClient()

  return async (fieldGroupId: number) => {
    await queryClient.prefetchQuery({
      queryKey: [
        'companies',
        divisionId,
        'card',
        'configuration',
        fieldGroupId,
      ] as QueryKey,
      queryFn: () => fetchLeaseCardSections(divisionId, fieldGroupId),
    })
  }
}
